<template>
  <div class="position-relative">
    <div v-if="canEdit" class="edit-icon-settings d-flex align-items-center">
      <feather-icon
        v-if="!isEditable"
        v-b-tooltip.hover.bottom
        icon="Edit2Icon"
        :title="$t('form-create-item.edit')"
        size="20"
        class="text-primary zindex-1"
        :class="
          !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="openEdit"
      />
      <feather-icon
        v-if="isEditable"
        icon="XIcon"
        size="20"
        class="text-primary zindex-1 mr-1"
        :class="
          !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="closeEdit"
      />
      <feather-icon
        v-if="isEditable"
        v-b-tooltip.hover.bottom
        icon="SaveIcon"
        :title="$t('header.action.save-layout')"
        size="20"
        class="text-primary zindex-1"
        :class="
          !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="$refs.baseHorizontalContainer.handleEditOrder();"
      />
    </div>
    <b-card>
      <div class="d-flex justify-content-between pr-1">
        <h4 class="card-title" style="text-transform: capitalize">
          {{ pluralType }}
        </h4>

        <div class=" d-flex justify-content-between">
          <v-select
            v-if="$route.params.typeKey === 'noKey'"
            v-model="typeSelected"
            class="select-control align-self-center mr-2"
            :clearable="false"
            label="name"
            :searchable="false"
            :options="entitiesTypes"
            style="width: 216px;"
          />
          <b-button
            v-if="isStaff || canAdd"
            v-b-modal.modal-create-entity
            class="align-self-center"
            variant="outline-primary"
          >
            {{ $t('entities.create', {entity:type != 'noKey' ? pluralType : $t('entities.pageTitle')}) }}
          </b-button>
        </div>
      </div>
      <div>
        <base-horizontal-container
          v-if="entities && entities.length > 0"
          ref="baseHorizontalContainer"
          #default="{ item }"
          :item-name="translatedType"
          :items="entities"
          item-type="entities"
          :is-draggable="isEditable"
          :search-type="typeSelected"
          :placeholder="placeholder"
          :placeholder-message="$t('entities.placeholder-text')"
          :loading-next="isLoadingNextPage"
          scroll-container-class="d-flex align-items-stretch"
          scrollable
          @changeOrder="changeOrder"
        >
          <entities-card :entity="item" :is-draggable="isEditable" @removed="handleRemoved($event)" />
        </base-horizontal-container>

        <b-row v-else class="horizontal-placeholder">
          <b-col cols="12">
            <img :src="placeholder">
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{ $t("entities.placeholder-text") }}
            </p>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Create Entity -->
    <b-modal
      id="modal-create-entity"
      :title="$t('entities.create', {entity: type != 'noKey' ? pluralType : $t('entities.pageTitle')})"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      :ok-disabled=" !selectedType || !formInput.name"
      @show="openModal"
      @ok="handleCreateItem"
    >
      <b-form>
        <b-form-group>
          <label
            for="name"
            class="font-weight-bold"
          >{{ $t("people.name") }}*</label>
          <b-form-input
            id="name"
            v-model="formInput.name"
            :placeholder="$t('form.type-placeholder')"
            type="text"
          />
        </b-form-group>

        <label
          for="type"
          class="font-weight-bold"
        >{{ $t("backoffice.settings.type") }}*</label>
        <v-select
          id="type"
          v-model="selectedType"
          :searchable="true"
          :placeholder="$t('form.select-placeholder')"
          :options="types"
          label="name"
          :clearable="false"
        />
        <b-form-group>
          <label class="mt-1 font-weight-bold" for="headline">{{
            $t("form-create-item.headline")
          }}</label>
          <b-form-input
            v-model="formInput.headline"
            :placeholder="$t('products.create-placeholder.headline')"
            type="text"
          />
        </b-form-group>

        <b-form-group>
          <label class="font-weight-bold">{{
            $t("members.edit.organizations.label")
          }}</label>
          <ajax-select
            v-model="formInput.organization"
            clearable
            searchable
            :placeholder="$t('organizations.search-placeholder')"
            dir="ltr"
            input-id="profile-organizations"
            :fetch-data="fetchData"
            label="name"
          />
        </b-form-group>

        <b-form-group>
          <label class="font-weight-bold" for="link">Link</label>
          <b-form-input
            v-model="formInput.link"
            :placeholder="$t('organizations.links-placeholder')"
            type="text"
          />
        </b-form-group>

        <b-form-group>
          <label class="font-weight-bold" for="description">{{
            $t("form-create-item.description")
          }}</label>
          <quill-editor v-model="formInput.description" />
        </b-form-group>

        <label class="font-weight-bold">{{
          $t("form-create-item.image")
        }}</label>
        <div class="border rounded p-2">
          <p class="mb-50">
            {{ $t("form-create-item.image-select") }}
          </p>
          <b-media
            no-body
            vertical-align="center"
            class="d-flex align-items-center flex-column flex-md-row"
          >
            <b-media-aside>
              <b-img
                ref="refPreviewEl"
                :src="imageSrc"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
                style="background: #c0c0c0"
              />
            </b-media-aside>
            <b-media-body>
              <div class="d-inline-block d-flex align-items-center pl-2">
                <b-form-file
                  v-model="itemImage"
                  class="pointer"
                  :accept="FileTypes"
                  :placeholder="$t('form-create-item.image-placeholder')"
                  :browse-text="$t('form-create-item.image-button')"
                />
              </div>
            </b-media-body>
          </b-media>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import GridContainer from '@core/components/containers/GridContainer.vue';
import { translateTranslationTable } from '@core/libs/i18n/utils';
import EntitiesCard from '@/views/apps/entities/components/EntitiesCard.vue';
import { quillEditor } from 'vue-quill-editor';
import FileTypes from '@/@core/constants/FileTypes';
import vSelect from 'vue-select';
import OrganizationsAPI from '@/views/apps/organization/store/OrganizationsAPI';
import AjaxSelect from '@core/components/ajax-select/AjaxSelect.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import EntitiesPlaceholder from '@/assets/images/placeholders/light/entities.svg';
import { checkPermissions } from '@/@core/utils/roles-utils';
import BaseHorizontalContainer from '@core/components/containers/base/BaseHorizontalContainer.vue';
import { AppIDLUT } from '@copernicsw/community-common';

export default {
  name: 'EntityDetails',
  components: {
    GridContainer,
    EntitiesCard,
    quillEditor,
    vSelect,
    AjaxSelect,
    BaseHorizontalContainer,
  },
  data() {
    return {
      typeKey: null,
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      formInput: {
        name: '',
        typeKey: '',
        description: '',
        headline: '',
      },
      formError: null,
      itemImage: null,
      imageSrc: null,
      typesForm: [],
      firstItems: [],
      actualItems: [],
      isEditable: false,
      selectedType: null,
      typeSelected: { name: this.$t('display-type-options.show-all') },
    };
  },
  computed: {
    translatedType() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.type.namePlural,
      );
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        'create',
        'entity',
        this.loggedMemberRoles,
        this.collective,
      );
    },
    canEdit() {
      return (
        checkPermissions(
          'update',
          'entity',
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },
    type() {
      return this.$route.params.typeKey;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    FileTypes() {
      return FileTypes;
    },
    entities() {
      return this.$route.params.typeKey === 'nokey'
        ? this.$store.getters.entities.unpaginated
        : this.$store.getters.entitiesByType[this.$route.params.typeKey]
          ?.unpaginated || [];
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    appId() {
      return 28;
    },
    app() {
      const appNameKey = AppIDLUT[this.appId];
      return this.$store.getters.apps.apps.find(
        ({ appKey }) => appKey === appNameKey,
      );
    },
    // translatedType() {
    //   if (this.typeKey === "noKey") {
    //     const app = Object.values(this.$store.getters.apps.apps)?.find(
    //       (item) => item.key === "entities"
    //     );

    //     return (
    //       translateTranslationTable(
    //         this.$store.state.locale.currentLocale,
    //         app.customizationName || app.name
    //       ) || this.$tc("entities.title", 2)
    //     );
    //   }

    //   return (
    //     translateTranslationTable(
    //       this.$store.state.locale.currentLocale,
    //       this.typeName
    //     ) || this.$tc("entities.title", 2)
    //   );
    // },type
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    placeholder() {
      return EntitiesPlaceholder;
    },
    pluralType() {
      const app = Object.values(this.$store.getters.apps.apps)?.find(
        (item) => item.key === 'entities',
      );
      if (this.type === 'noKey') {
        return (
          translateTranslationTable(
            this.$store.state.locale.currentLocale,
            app?.customizationName || app?.name,
          ) || this.$tc('entities.title', 2)
        );
      }

      if (app.customization?.[this.type] != null) {
        return (
          translateTranslationTable(
            this.$store.state.locale.currentLocale,
            app.customization?.[this.type]?.customizationName
              || app.customization?.[this.type],
          )
          || Object.values(
            app.customization?.[this.type]?.customizationName
              || app.customization?.[this.type],
          )
        );
      }
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.type.namePlural,
      );
    },
    types() {
      if (this.$store.getters.types.AllEntitiesTypes) {
        const typesSelect = [];
        const uniqueChars = this.$store.getters.types.AllEntitiesTypes.unpaginated.filter((item, index, allTypes) => index === allTypes.findIndex((type) => (
          type.key === item.key
        )));
        uniqueChars.map((item) => typesSelect.push({
          name: this.translate(item.name),
          key: item.key,
        }));
        return typesSelect;
      } return [];
    },
    entitiesTypes() {
      const typesSelect = [{ name: this.$t('display-type-options.show-all') }];
      if (this.entities.length > 0) {
        this.entities.map((item) => (item.type
          ? typesSelect.push({ name: this.translate(item.type.name) }) : ''));
        return typesSelect.filter((item, index, allTypes) => index === allTypes.findIndex((type) => (
          type.name === item.name
        )));
      }
      return typesSelect;
    },
  },
  async created() {
    await this.fetchEntities();
    await this.getTypes();
    this.updateBreadcrumbs(this.pluralType);
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    openEdit() {
      this.firstItems = [...this.entities];
      this.isEditable = true;
    },
    closeEdit() {
      this.$store.commit('SET_ITEMS', {
        type: 'entities',
        ...(this.$route.params.typeKey === 'nokey'
          ? ''
          : { customName: 'entitiesByType' }),
        ...(this.$route.params.typeKey === 'nokey'
          ? ''
          : { storedKey: this.$route.params.typeKey }),
        forceAPICall: true,
        page: 1,
        data: {
          data: this.firstItems,
          meta: {
            current_page: 1,
            total: this.firstItems.length,
          },
        },
      });
      this.isEditable = false;
    },
    changeOrder(items) {
      this.isEditable = false;
    },
    fetchData(page, searchString = '') {
      return OrganizationsAPI.fetchPage({
        communityKey: this.$store.getters.currentCollective.key,
        listingType: 0,
        searchString: searchString || '',
        typeKey: null,
        page,
        perPage: 16,
      });
    },
    async fetchEntities() {
      await this.$store.dispatch('getItems', {
        itemType: 'entities',
        ...(this.$route.params.typeKey === 'nokey'
          ? ''
          : { customName: 'entitiesByType' }),
        ...(this.$route.params.typeKey === 'nokey'
          ? ''
          : { storedKey: this.$route.params.typeKey }),
        forceAPICall: true,
        page: 1,
        requestConfig: {
          count: 100,
          orderByDate: -1,
          ...(this.$route.params.typeKey === 'nokey'
            ? ''
            : { typeKey: this.$route.params.typeKey }),
        },
      });
    },
    async getTypes() {
      await this.$store.dispatch('getItems', {
        itemType: 'types',
        storedKey: 'AllEntitiesTypes',
        page: 1,
        perPage: 200,
        requestConfig: {
          modelType: 'entity',
          getCommunityTypes: 1,
          selectAll: 1,
        },
      });
    },
    async handleCreateItem() {
      if (!this.formInput.name) {
        return;
      }
      try {
        const requestConfig = { name: {}, description: {}, headline: {} };
        requestConfig.typeKey = this.selectedType.key;

        const locale = this.$store.state.locale.currentLocale;
        requestConfig.name[locale] = this.formInput.name;
        requestConfig.link = this.formInput.link;
        requestConfig.description[locale] = this.formInput.description;
        requestConfig.headline[locale] = this.formInput.headline;
        requestConfig.organizationKey = this.formInput.organization?.key;

        const response = await this.$store.dispatch('createItem', {
          item: {
            itemType: 'entities',
            requestConfig,
            ...(this.$route.params.typeKey === 'nokey'
              ? ''
              : { storedKey: this.$route.params.typeKey }),
            ...(this.$route.params.typeKey === 'nokey'
              ? ''
              : { customName: 'entitiesByType' }),
          },
          file: this.itemImage,
        });

        this.formInput = {};
        this.itemImage = null;
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('entities.messages.success-create'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('entities.messages.error-create'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    handleItemsLoaded(response) {
      this.typeKey = response.find(
        ({ type }) => type.key === this.$route.params.typeKey,
      ).type.name;
      this.updateBreadcrumbs(this.pluralType);
    },
    updateBreadcrumbs(translatedType) {
      let breadcrumbs;
      if (translatedType) {
        breadcrumbs = [
          {
            text: translatedType,
            active: true,
          },
        ];
      } else {
        breadcrumbs = [
          {
            text: 'entities.breadcrumb-text',
            active: true,
          },
        ];
      }
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    async handleRemoved(key) {
      await this.fetchEntities();
    },
    openModal() {
      if (this.types.length > 0) {
        const index = this.types.findIndex((item) => item.key === this.type);
        if (index >= 0) {
          this.selectedType = this.types[index];
        } else this.selectedType = this.types[0];
      }
      return {};
    },
  },
};
</script>
<style scoped>
.selection {
  position: absolute;
  right: 186px;
  top: 24px;
}
.selectedForItems {
  --vs-dropdown-max-height: 100px;
}
.edit-icon-settings {
  position: absolute;
  right: 0px;
  top: -50px;
  cursor: pointer;
}
</style>
